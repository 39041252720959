<template>
  <div class="list">
    <list-search
      @onSearch="onSearch"
      @clearSearch="clearSearch"
      :childData="childData"
    ></list-search>

    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>

    <div class="tableList">
      <div class="table">
        <el-table :data="tableData" ref="tabledeatailData">
          <el-table-column label="序号">
            <template #default="scope">
              {{ scope.row.serialNumber }}
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="评分项"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="trueScore"
            label="基准分"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              {{ scope.row.status == 1 ? "启用" : "停用" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="scoreDetailList"
            label="评分细项"
            :show-overflow-tooltip="true"
          >
            <template #default="scope">
              <el-button
                type="text"
                size="mini"
                @click="scoreDetailBtn(scope.row)"
                >评分细项</el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template #default="scope">
              <el-button type="text" size="mini" @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="blockup(scope.row, false)"
                v-if="scope.row.status == 1 ? true : false"
                >停用</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="blockup(scope.row, true)"
                v-if="scope.row.status == 1 ? false : true"
                >启用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="tablepage" v-if="tableData && tableData.length > 0">
          <el-pagination
            @current-change="pageChange"
            :page-size="paginationData.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="paginationData.total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <el-dialog title="新增评分项目" v-model="dialogFormVisible">
      <el-form
        class="content bascform"
        ref="form"
        :model="form"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="序号" required prop="serialNumber">
          <el-input v-model="form.serialNumber"></el-input>
        </el-form-item>
        <el-form-item label="评分项" required prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="基准分" required prop="trueScore">
          <el-input v-model="form.trueScore"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="xixiang">下一步增加细项</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- <dialog-box
      ref="addDialog"
      :dialogShow="DialogShow"
      @handleClose="handleClose"
      @nextadd="nextadd"
      title="新增评分项目"
      componentName="AddscoreDialog"
      :buttonData="[
        {
          label: '下一步增加细项',
          action: 'nextadd',
          type: 'primary',
          size: 'mini',
        },
      ]"
    >
    </dialog-box> -->

    <el-dialog title="修改评分项目" v-model="EditDialogShow">
      <el-form
        class="content bascform"
        ref="form1"
        :model="form"
        label-width="100px"
      >
        <el-form-item label="序号" required prop="serialNumber">
          <el-input v-model="form.serialNumber"></el-input>
        </el-form-item>
        <el-form-item label="评分项" required prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="基准分" required prop="trueScore">
          <el-input v-model="form.trueScore"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="que">确认</el-button>
          <el-button type="primary" @click="qu">取消</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- <dialog-box
      :dialogShow="EditDialogShow"
      @handleClose="handleClose"
      @confirmAdd="confirmAdd"
      title="编辑评分项目"
      componentName="EditAddscoreDialog"
      :buttonData="[
        { label: '取消', action: 'handleClose', size: 'mini' },
        { label: '确认', action: 'confirmAdd', type: 'primary', size: 'mini' },
      ]"
    >
    </dialog-box> -->
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import {
  SearchList,
  SearchData,
  tableField,
  tableData,
  menudata
} from './js/reviewProjectOps'
import { request } from '@/assets/js/http.js'
// import dayjs from 'dayjs'
export default {
  components: { ...Component.components },
  name: 'ReviewProjectOps',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      rules: {
        serialNumber: [
          { required: true, message: '请输入序号', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入评分项', trigger: 'blur' }],
        trueScore: [
          { required: true, message: '请输入基准分', trigger: 'blur' }
        ]
      },
      dialogFormVisible: false,
      id: '',
      tableField: tableField,
      tableData: tableData,
      menudata: menudata,
      DialogShow: false,
      EditDialogShow: false,
      showFormData: {},
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      pageNum: 1,
      childData: { searchList: SearchList, searchData: SearchData },
      form: { serialNumber: '', name: '', trueScore: '' }
    }
  },
  created () {
    this.scoreList()
  },
  methods: {
    // 编辑时点击取消
    qu () {
      this.form = { serialNumber: '', name: '', trueScore: '' }
      this.EditDialogShow = false
    },
    // 编辑时点击确认
    que () {
      const obj = {
        id: this.id,
        name: '',
        serialNumber: '',
        status: 1,
        trueScore: ''
      }
      obj.name = this.form.name
      obj.serialNumber = this.form.serialNumber
      obj.trueScore = this.form.trueScore
      console.log(obj)

      request('/api/quality/score/save', 'post', obj).then((res) => {
        if (res.code === '200') {
          console.log(res)
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.scoreList()
          this.EditDialogShow = false
        }
      })
    },
    // 点击下一步增加细项
    xixiang () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message({
            message: '请输入全部的必选项',
            type: 'warning'
          })
        } else {
          this.dialogFormVisible = false
          this.nextadd()
        }
      })
    },

    buttonClick: function (data) {
      this.DialogShow = true
      this.dialogFormVisible = true
      this.form = { serialNumber: '', name: '', trueScore: '' }
    },
    // 点击停用、启用的时候
    blockup (data, show) {
      // console.log(data, show);
      const obj = { ...data }
      //  console.log(obj);
      if (show) {
        obj.status = 1
      } else {
        obj.status = 0
      }
      request('/api/quality/score/save', 'post', obj).then((res) => {
        if (res.code === '200') {
          // console.log(res);1
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.scoreList()
        }
      })
    },
    // 点击编辑时
    edit (data) {
      this.form = { serialNumber: '', name: '', trueScore: '' }
      this.id = data.id
      this.EditDialogShow = true
    },
    nextadd () {
      const obj = {
        name: '',
        serialNumber: '',
        status: 1,
        trueScore: ''
      }
      obj.name = this.form.name
      obj.serialNumber = this.form.serialNumber
      obj.trueScore = this.form.trueScore
      console.log(obj)

      request('/api/quality/score/save', 'post', obj).then((res) => {
        if (res.code === '200') {
          console.log(res)
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push({
            params: { viewName: 'ScoreDetail' },
            query: { id: res.data }
          })
        }
      })
    },

    scoreDetailBtn (data) {
      // 评分细项
      const id = data.id
      this.$router.push({
        params: { viewName: 'ScoreDetail' },
        query: {
          id
        }
      })
    },

    handleClose () {
      this.DialogShow = false
      this.EditDialogShow = false
    },
    confirmAdd () {
      this.DialogShow = false
    },
    scoreList (cc) {
      // let startTime = "";
      // let endTime = "";
      // if (this.childData.searchData.cjTime) {
      //   startTime = dayjs(this.childData.searchData.startTime[0]).format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   );
      //   endTime = dayjs(this.childData.searchData.endTime[1]).format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   );
      // }
      const obj = {
        // tzDh: this.childData.searchData.tzDh,
        // qualityCode: this.childData.searchData.qualityCode,
        // startTime: startTime,
        // endTime: endTime,
        // supplier: this.childData.searchData.supplier,
        // type: this.childData.searchData.type,
        // status: this.childData.searchData.status,
        // plant: this.childData.searchData.plant,
        serialNumber: this.childData.searchData.serialNumber,
        name: this.childData.searchData.name
      }

      request(
        '/api/quality/score/list?' +
          'pageNum=' +
          this.pageNum +
          '&' +
          'pageSize=' +
          this.paginationData.pageSize,
        'GET',
        obj
      ).then((res) => {
        if (res.code === '200') {
          console.log(res, 1111)
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    tableAction: function (index, data, clickBtn) {
      request(`/api/quality/data/getById/${data.id}`, 'get').then((res) => {
        if (res.code === '200') {
          this.showFormData = res.data
          this.showDialogShow = true
        }
      })
    },

    onSearch (data) {
      this.pageNum = 1
      this.scoreList()
    },
    clearSearch (data) {
      this.pageNum = 1
      this.scoreList()
    },
    pageChange (data) {
      console.log(11)
      this.pageNum = data
      this.scoreList()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/tableList.scss";
</style>
