export var SearchList = [
  { labe: '序号', code: 'serialNumber', type: 'input', placeholder: '编码/名称' },
  { labe: '评分项', code: 'name', type: 'input', placeholder: '编码/名称' }
]
export var SearchData = {
  // supplier: '',
  // cjTime: '',
  // type: ''
  serialNumber: '',
  name: ''
}
export var tableField = [
  { label: '序号', code: 'code', type: 'input' },
  { label: '评分项', code: 'supplierCode', type: 'input', width: '100px' },
  { label: '基准分', code: 'supplierName', type: 'input', width: '120px' },
  { label: '评分细项', code: 'bz', type: 'input', width: '130px' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '80px',
    handle: function (index, row) {
      return row.fileType === 1 ? '启用' : '停用'
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '80px',
    type: 'action',
    actionData: [
      {
        actionLabel: '编辑',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = [
  {
    status: 1
  }
]
export var menudata = [{
  label: '+新增评分项',
  action: 'draftContract',
  id: 1
}
]
